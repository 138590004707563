import request from '@/utils/request'

// 查询运营商通用短信模版
export function findCarrierSmsTemplates(params) {
  return request({
    url: `/carrier_sms_templates`,
    method: 'get',
    params: params
  })
}

// 查询可导入的运营商通用短信模版
export function findImportableSmsTemplates(params) {
  return request({
    url: `/sms_templates/importable`,
    method: 'get',
    params: params
  })
}

// 创建运营商通用短信模版
export function importCarrierSmsTemplates(data) {
  return request({
    url: `/carrier_sms_templates/import`,
    method: 'post',
    data
  })
}

// 查询单个运营商通用短信模版表单值
export function findCarrierSmsTemplateForm(id) {
  return request({
    url: `/carrier_sms_templates/${id}/form`,
    method: 'get'
  })
}

// 更新运营商通用短信模版
export function updateCarrierSmsTemplate(id, data) {
  return request({
    url: `/carrier_sms_templates/${id}`,
    method: 'put',
    data
  })
}

// 批量更新运营商通用短信模版有效性
export function batchUpdateCarrierSmsTemplatesEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/carrier_sms_templates/effective/batch`,
    method: 'patch',
    data
  })
}

// 运营商账户的短信模版选项数据
export function findCarrierAccountSmsTemplateOptions(carrierAccountId) {
  return request({
    url: `/carrier_sms_templates/options`,
    method: 'get',
    params: { carrier_account_id: carrierAccountId }
  })
}

// 查询运营商通用短信模版
export function exportCarrierSmsTemplates(params) {
  return request({
    url: `/carrier_sms_templates/export`,
    method: 'post',
    params
  })
}
