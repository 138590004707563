<template>
  <div>
    <search-carrier-sms-template @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showImportModal"
          v-if="isShowMenu('carrier_sms_templates/import')"
        >导入通用模版
        </a-button>

        <update-carrier-sms-template-effective
          v-if="isShowMenu('carrier_sms_templates/effective')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <span slot="carrier_sms_template_id" slot-scope="text" :class="{ 'pending-set': text === '待设置'}">
        {{ text }}
      </span>

      <span slot="actions" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="isHasEditPermission">
              <a @click="showEditModal(record.id)">编辑</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增模态框 -->
    <import-carrier-sms-template
      v-if="isShowImportModal"
      :carrier-account-id="carrierAccountId"
      :visible.sync="isShowImportModal"
      @completed="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-carrier-sms-template
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      :carrier-account-id="carrierAccountId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findCarrierSmsTemplates } from '@/api/carrier_sms_template'
import SearchCarrierSmsTemplate from '@/views/sms_templates/carrier/Search'
import Pagination from '@/components/Pagination'

export default {
  name: 'CarrierSmsTemplateList',
  components: {
    SearchCarrierSmsTemplate,
    Pagination,
    ImportCarrierSmsTemplate: () => import('@/views/sms_templates/carrier/import/index'),
    EditCarrierSmsTemplate: () => import('@/views/sms_templates/carrier/Edit'),
    UpdateCarrierSmsTemplateEffective: () => import('@/views/sms_templates/carrier/UpdateEffective')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowImportModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingId: 0, // 正在编辑的记录id
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: { effective: 1 },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '模版名称',
          fixed: 'left',
          dataIndex: 'name'
        },
        {
          title: '模版内容',
          dataIndex: 'content'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '运营商模版CODE',
          dataIndex: 'carrier_sms_template_code',
          scopedSlots: { customRender: 'carrier_sms_template_code' }
        },
        {
          title: '有效性',
          dataIndex: 'effective'
        },
        {
          title: '操作',
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    carrierAccountId() {
      return parseInt(this.$route.params.id)
    },

    isHasEditPermission() {
      return hasPermission('carrier_sms_templates/edit')
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showImportModal() {
      this.isShowImportModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findCarrierSmsTemplates(Object.assign({ carrier_account_id: this.carrierAccountId }, this.query, this.sort))
        .then((res) => {
          this.data = res.data
          this.pagination = res.pagination
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.pending-set {
  color: red;
}
</style>
